import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import MetroFares from "../components/metro-fares"

class BY21ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="BY25"
          description="View route information and buy your ticket for the BY25 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">BY25 service</h1>
              <p>Cross Green Lane – Bishop Young Academy</p>
              <p>Bishop Young Academy – Cross Green Lane</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="BY25 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1ypPzai1rx5mVqMjFy8lJrfagGrGzOzc&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Bishop Young Academy
                </p>
                <p>
                  From Cross Green Lane, Pontefract Lane, Park Parade, East Park Parade, Ivy Street, York Road, Lupton Avenue, Harehills Lane, Strathmore Drive, Foundry Drive, Foundry Avenue, North Farm Road, Oak Tree Drive, Amberton Road, Foundry Lane, South Parkway Approach, South Parkway, Kentmere Avenue, North Parkway, Bishops Way.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="BY25 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1itdvoGu-rjVLGdz9iDyvabjmEhgFY9g&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>

                <p>
                  <strong>From</strong> Bishop Young Academy
                </p>
                <p>
                  From Bishops Way, North Parkway, Kentmere Avenue, South Parkway Appoach, South Parkway, Foundry Lane, Amberton Road, Oak Tree Drive, North Farm Road, Foundry Avenue, Foundry Drive, Strathmore Drive, Harehills Lane, Lupton Avenue, York Road, Ivy Street, East Park Parade, Park Parade, Pontefract Lane, Cross Green Lane.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cross Green Lane/Copperfield Avenue</td>
                      <td>DEP</td>
                      <td>0740</td>
                    </tr>
                    <tr>
                      <td>Lupton Avenue</td>
                      <td>DEP</td>
                      <td>0745</td>
                    </tr>
                    <tr>
                      <td>Foundry Drive</td>
                      <td>DEP</td>
                      <td>0750</td>
                    </tr>
                    <tr>
                      <td>South Parkway</td>
                      <td>DEP</td>
                      <td>0800</td>
                    </tr>
                    <tr>
                      <td>Bishop Young Academy</td>
                      <td>ARR</td>
                      <td>0805</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Bishop Young Academy</td>
                      <td>DEP</td>
                      <td>1500</td>
                    </tr>
                    <tr>
                      <td>South Parkway</td>
                      <td>DEP</td>
                      <td>1505</td>
                    </tr>
                    <tr>
                      <td>Foundry Drive</td>
                      <td>DEP</td>
                      <td>1515</td>
                    </tr>
                    <tr>
                      <td>Lupton Avenue</td>
                      <td>DEP</td>
                      <td>1520</td>
                    </tr>
                    <tr>
                      <td>Cross Green Lane/Copperfield Avenue</td>
                      <td>ARR</td>
                      <td>1525</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default BY21ServicePage
